import { Button, Collapse } from "antd";
import React, { Dispatch, SetStateAction, useContext, useEffect } from "react";
import closeIcon from "src/style/images/close_icon.svg";
import { CloseCircleOutlined, SettingFilled } from "@ant-design/icons";
import EditPanel from "./EditPanel";
import { GlobalProps, LayoutProps, SocketNames } from "src/interface/PiqState";
import { CaretRightOutlined } from "@ant-design/icons";
import KeywordHighlight from "./KeywordHighlight";
import useColumnHeader from "src/Hooks/useColumnHeader";
import FilterPanel from "./FilterPanel";
import CustomFeedNameEdit from "./CustomFeedNameEdit";
import CustomFeedAdd from "./CustomFeedAdd";
import { customFeedsSockets } from "src/SuiteConfig/columnConfiguration";
const { Panel } = Collapse;
const CustomColumnHeader: React.FC<{
    setShowEditPanel: (params: any) => any;
    socket: SocketNames;
    props: GlobalProps;
    layoutProps: LayoutProps;
    defaultActivePanel?: string;
    handleAddCustomFeed: any
    handleChangeTitle: any
    title: any
    setTitle: any
    keyToRefresh: any
    setKeyToRefresh: any
    setOpenByDefault: any
}> = ({ setShowEditPanel, socket, props, layoutProps, defaultActivePanel, handleAddCustomFeed, handleChangeTitle, title, setTitle, keyToRefresh, setKeyToRefresh, setOpenByDefault }) => {
    const { removeCustomColumn, generateKey, setGenerateKey } = useColumnHeader(layoutProps);

    return (
        <div
            className="v2-column-header-option"
        // style={{
        //   height: socket === "harkster" || socket === "forexLive" || socket === "telegram" ? "96%" : socket === "reuters" ? "94%" : socket === "tradingview" ? "91%" : "100%",
        //   width: socket === "harkster" || socket === "forexLive" ? "96%" : socket === "reuters" ? "98.6%" : "99%",

        // }}
        >
            <div style={{ textAlign: "right" }}>
                <Button
                    type="text"
                    shape="circle"
                    size="small"
                    className="v2-column-header-option-close-btn"
                    icon={
                        <img
                            src={closeIcon}
                            alt=""
                            className="v2-column-header-option-close-btn-icon"
                        />
                    }
                    onClick={() => setShowEditPanel(false)}
                />
            </div>

            {socket === "tradingview" ||
                socket === "investing-economic-calender" ||
                socket === "telegram" ? (
                ""
            ) : (
                <>
                    <div className="v2-edit-panel-setting-title-div">
                        {customFeedsSockets.some(feed => socket.includes(feed)) ? (
                            <div className="v2-edit-panel-setting-title">
                                Custom Feed Settings
                            </div>
                        ) : (
                            <div className="v2-edit-panel-setting-title">Feed Settings</div>
                        )}

                        <SettingFilled className="v2-edit-panel-setting-icon" />
                    </div>

                    <div className="settings-divider"></div>

                    <Collapse
                        accordion={Array.isArray(defaultActivePanel) ? false : true}
                        className="v2-edit-panel-collapse1"
                        bordered={false}
                        expandIconPosition={"end"}
                        expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        activeKey={defaultActivePanel ?? ""}
                        key={keyToRefresh}
                        onChange={(e) => {
                            if (e) {
                                setOpenByDefault(e[e.length - 1])
                            } else {
                                setOpenByDefault("")
                            }
                        }}
                    >
                        <Panel
                            header="Feed Name"
                            key="1"
                            style={{
                                border: "none",
                                marginBottom: "5px",
                                cursor: "pointer",
                            }}
                        >
                            <CustomFeedNameEdit socket={socket} props={props} handleChangeTitle={handleChangeTitle} title={title} setTitle={setTitle} />
                        </Panel>
                        <Panel
                            header="Select Feeds"
                            key="2"
                            style={{
                                border: "none",
                                marginBottom: "5px",
                                cursor: "pointer",
                            }}
                        >
                            <CustomFeedAdd socket={socket} props={props} handleAddCustomFeed={handleAddCustomFeed} generateKey={generateKey} setGenerateKey={setGenerateKey} />
                        </Panel>
                        <Panel
                            header="Keyword Alerts"
                            key="3"
                            style={{ border: "none", marginBottom: "5px", cursor: "pointer" }}
                        >
                            <KeywordHighlight socket={socket} props={props} />
                        </Panel>

                        <Panel
                            header="Change Feed"
                            key="4"
                            style={{ border: "none", marginBottom: "5px", cursor: "pointer" }}
                        >
                            <EditPanel socket={socket} layoutProps={layoutProps} />
                        </Panel>
                        <Panel
                            header="Conditional Filter"
                            key="5"
                            style={{ border: "none" }}
                        >
                            <FilterPanel
                                socket={socket}
                                props={props}
                            // layoutProps={layoutProps}
                            />
                        </Panel>
                    </Collapse>

                    <div className="settings-divider"></div>
                </>
            )}

            <div
                style={{ color: "red", cursor: "pointer", marginBottom: "100px" }}
                onClick={() => removeCustomColumn(socket, layoutProps)}
            >
                <CloseCircleOutlined /> Delete Custom Feed
            </div>
        </div>
    );
};

export default CustomColumnHeader;
