export const enum trackEvents {
  ARTICLE_LIMIT_SIGNUP_BTN = "ALIMIT_SU_BTN",
  INPUT_FIELD_COMPLETE = "INPUT_FIELD_COMPLETE",
  SIGNUP_WITH_EMAIL_BTN_CLICK = "S_U_WITH_EMAIL_BTN",
  SIGNUP_TC = "S_U_TC",
  SIGNUP_EMAIL_UPDATES = "S_U_EU",
  SIGNUP_SUBMIT_BTN = "S_U_BTN",
  SIGNUP_SUCCESS = "SIGNUP_SUCCESS",

  ADD_CLICK = "AD_CLICK",

  HEADER_BOOKMARK_CLICK = "HDR_BK_BTN",

  BOOKMARK_ADD_BTN = "BK_AD_BTN",
  BOOKMARK_REMOVE_BTN = "BK_RM_BTN",

  CLICK_GLOBAL_SEARCH_FIELD = "HDR_SEARCH_BTN",
  GLOBAL_SEARCH_QUERY = "HDR_SEARCH_QRY",

  FEED_SEARCH_BTN = "FD_HDR_SEARCH_BTN",
  FEED_REFRESH_BTN = "FD_HDR_RLOAD_BTN",
  FEED_FILTER_BTN = "FD_HDR_FLTR_BTN",
  FEED_SETTING_BTN = "FD_HDR_ST_BTN",
  FEED_FILTER_APPLY = "FD_ST_APLY_FLTR",
  FEED_SCROLL = "FD_SCRL",
  FEED_SESSION_TIME = "FD_SES_TIME",
  FEED_CHANGE = "FD_ST_CNG_FEED",
  FEED_SETTING_CLOSE = "FEED_ST_CLS",

  REGISTER_DRAWER_OPEN = "R_D_O",
  SIGN_IN_DRAWER_OPEN = "S_I_D_O",
  RESET_PASSWORD_DRAWER_OPEN = "R_P_D_O",
  REGISTER_DRAWER_CLOSED = "R_D_C",
  RESET_PASSWORD_DRAWER_CLOSED = "R_P_D_C",
  SIGN_IN_DRAWER_CLOSED = "S_I_D_C",

  ACTIVE_SESSION = "ACTIVE_SESSION",

  LOGIN_FAIL = "LGN_FAIL",
  LOGIN_SUCCESS = "LGN_SUCC",

  SIGN_IN_SUBMIT = "S_I_SUBMIT",

  FEED_ADD = "APS_FD_AD",
  FEED_REMOVE = "APS_FD_RM",

  FEED_HAS_KEYWORD = "FD_ST_HAS_KYWRD",
}
