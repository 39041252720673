import React, { useState } from 'react'
import { GlobalProps, LayoutProps, SocketNames } from 'src/interface/PiqState';
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import ForexCrossRatesWidget from './ForexCrossRatesWidget';
import useFeeds from 'src/Hooks/useFeeds';

const ForexCrossRates: React.FC<{
    socket: SocketNames;
    props: GlobalProps;
    layoutProps: LayoutProps;
}> = ({ socket, props, layoutProps }) => {
    const [showEditPanel, setShowEditPanel] = React.useState<boolean>(false);
    const [isFetching, setFetching] = React.useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);


    const { getColumnDetail } = useFeeds(props);
    const column = getColumnDetail(socket);

    const refresh_function = () => {
        setFetching(true);
        setReload(true)
    };

    return (
        <div className="view-chart-div">
            <ColumnHeader
                title={column?.title || ""}
                socket={socket}
                isFetching={isFetching}
                fetchFunction={() => refresh_function()}
                handleEditPanel={() => setShowEditPanel(true)}
            />
            {showEditPanel && socket ? (
                <ColumnHeaderOption
                    props={props}
                    setShowEditPanel={setShowEditPanel}
                    socket={socket}
                    layoutProps={layoutProps}
                />
            ) : (
                <ForexCrossRatesWidget
                    reload={isFetching}
                    setFetching={setFetching}
                    setReload={setReload}
                />
            )}
        </div>
    )
}

export default ForexCrossRates