import React from "react";
import ColumnLoading from "../Loading";
import { GlobalProps, SocketNames } from "src/interface/PiqState";
import { SingleKeywordColConfig } from "src/interface/keyword";
import {
    checkIfDivHasHighlight,
} from "src/utils/utilFunction";
import { useAuthContext } from "src/context/AuthProvider";
import { useSelector } from "react-redux";
import { InFeedAds } from "../InFeedAds";
import FeedSearchNotFound from "../Feeds/FeedSearchNotFound";
import useFeeds from "src/Hooks/useFeeds";
import { FeedItem } from "../PartnerFeed/FeedItem";
import { CustomFeedItem } from "./CustomFeedItem";
import NotFoundCustom from "../Feeds/NotFoundCustom";
import { lowercaseFirstLetter } from "src/utils/common";

const CustomNewsFeedList: React.FC<{
    list: any[];
    socket: SocketNames;
    twitterAccount?: string;
    colConfig: SingleKeywordColConfig;
    props: GlobalProps;
    isSearchLoading: boolean;
    searchedText: string;
    filterActive: boolean;
    isWidth2: boolean
}> = ({
    list,
    socket,
    twitterAccount,
    colConfig,
    props,
    isSearchLoading,
    searchedText,
    filterActive,
    isWidth2
}) => {
        const { isSignedIn: isUserLoggedIn } = useAuthContext();
        const darkMode = useSelector((state: any) => state?.user?.darkMode);
        const { divHasHighligts } = useFeeds(props)
        return (
            <>
                {list?.length ? (
                    list?.map((item: any, index: number) => {
                        return (
                            <div key={index}>
                                <div key={item?._id}>
                                    <InFeedAds item={item} props={props} isWidth2={isWidth2} />
                                    <div
                                        hidden={item?.list_item_type === "IN_FEED_ADS"}
                                        style={{
                                            padding: "5px 10px 8px 15px",
                                            border:
                                                isUserLoggedIn &&
                                                    colConfig?.highlightEntireCard &&
                                                    divHasHighligts(item?.title, colConfig)
                                                    ? `${`2px solid ${divHasHighligts(
                                                        item?.title,
                                                        colConfig
                                                    )}`}`
                                                    : "",
                                            backgroundColor: darkMode
                                                ? isUserLoggedIn &&
                                                    colConfig?.highlightEntireCard &&
                                                    checkIfDivHasHighlight(item?.title, colConfig)
                                                    ? "#515A67"
                                                    : "#384251"
                                                : "white",
                                            color:
                                                isUserLoggedIn &&
                                                    checkIfDivHasHighlight(item?.title, colConfig)
                                                    ? "white"
                                                    : "none",
                                        }}
                                        className={`v2-suite-column-card-item${item.sourceName === "reuters" && item?.version === 1
                                            ? "-reuters"
                                            : ""
                                            }`}
                                    >
                                        <CustomFeedItem
                                            item={item}
                                            socket={item.socketName}
                                            sourceName={item.sourceName}
                                            twitterAccount={twitterAccount}
                                            colConfig={colConfig}
                                            props={props}
                                        />
                                    </div>
                                </div>

                            </div>
                        );
                    })
                ) : isSearchLoading ? (
                    <ColumnLoading />
                ) : (
                    filterActive ? <FeedSearchNotFound searchedText={searchedText} /> :
                        <NotFoundCustom />
                )}
            </>
        );
    };
export default CustomNewsFeedList;
