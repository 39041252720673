import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNewsquawkAudio } from "src/Hooks/useNewsquawkAudio";
import { GlobalProps } from "src/interface/PiqState";

const NewsquawkAudio: React.FC<{
    socket: string;
    props: GlobalProps
}> = ({ socket, props }) => {
    const playerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const darkMode = useSelector((state: any) => state?.user?.darkMode);

    const { fetchNewsquawkAudio, channels } = useNewsquawkAudio();

    useEffect(() => {
        fetchNewsquawkAudio();
    }, [])

    useEffect(() => {
        const loadScript = async () => {
            const script = document.createElement('script');
            script.src = 'https://audio.newsquawk.com/js/player/widget.js';
            script.crossOrigin = 'anonymous';
            script.async = true;
            script.defer = true

            if (containerRef.current) {
                containerRef.current.appendChild(script)
            }

            return new Promise((resolve) => {
                script.onload = resolve;
            });
        };

        const link = document.createElement("link");
        link.href = 'https://audio.newsquawk.com/css/player/widget.css';
        link.rel = 'stylesheet';
        document.head.append(link);

        if (channels) {
            loadScript().then(() => {
                const script = document.createElement('script');
                script.innerHTML = "WebRtcPlayer({ selector: '#player', delivery: 'webrtc' });";
                script.crossOrigin = 'anonymous';
                script.async = true;
                if (containerRef.current) {
                    containerRef.current.appendChild(script)
                }
            });
        }

        return () => {
            const script = document.querySelector('script[src="https://audio.newsquawk.com/js/player/widget.js"]');
            const link = document.querySelector('link[href="https://audio.newsquawk.com/css/player/widget.css"]');
            const stopBtn = document.querySelector("a.stop_button") as HTMLAnchorElement;
            if (stopBtn) {
                stopBtn.click();
            }
            if (containerRef.current && script) {
                containerRef.current.removeChild(script);
            }
            if (link) {
                document.head.removeChild(link);
            }

        };
    }, [channels]);

    return (
        <div ref={containerRef}>
            {
                channels && channels?.length > 0 && <div id="player" ref={playerRef} data-channels={JSON.stringify(channels)} className={`newsquawk-audio-container ${darkMode ? "dark" : ""}`}></div>
            }
        </div>
    )
};

export default NewsquawkAudio;
