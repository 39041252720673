import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Divider, Skeleton, Switch, Tooltip, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useAuthContext } from "src/context/AuthProvider";
import SingleKeywordHighlights from "./SingleKeywordHighlights";
import { KEYWORD_COLOR_LIST } from "src/SuiteConfig/keywordColorList";
import {
  postSingleColumnKeyword,
} from "src/store/PIQ/action";
import useColConfig from "src/Hooks/useColConfig";
import { GlobalProps } from "src/interface/PiqState";
import { useIndividualFeedContext } from "src/context/IndividualFeedProvider";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";

const KeywordHighlight: React.FC<{
  socket: string;
  props: GlobalProps
}> = ({ socket, props }) => {
  const [tag, setTag] = useState<any>("");
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const context: any = useAuthContext();
  const [messageApi, contextHolder] = message.useMessage();

  const { colConfig: data, setColConfig, loading } = useColConfig({ socket, showEditPanel: true });
  const { hasSubscriptionForAdsRemoval } = useSelector((state: any) => ({
    hasSubscriptionForAdsRemoval: state.subscription?.data?.active || false
  }))

  const { keywordEventSent, setKeywordEventSent } = useIndividualFeedContext();
  const { sendEvent } = useTrackerContext();


  const addKeyword = (e: any) => {
    if (e.target.value.trim().length === 0) {
      messageApi.open({
        type: 'error',
        content: 'Please enter a keyword.',
      });
      setTag("");
    } else {
      const tags = data?.singleWordPhraseKeywords;
      const existingTagIndex = tags.findIndex(
        (element: any) => element?.name?.toLowerCase() === e.target.value.trim()?.toLowerCase()
      );
      if (existingTagIndex !== -1) {
        messageApi.open({
          type: 'error',
          content: 'This keyword is already entered.',
        });
      }
      if (existingTagIndex === -1 && e.target.value !== "") {
        const maxTagsLimit = hasSubscriptionForAdsRemoval ? 25 : 3;

        if (tags.length < maxTagsLimit) {
          let colorIndex = 0;
          if (tags.length < KEYWORD_COLOR_LIST.length) {
            colorIndex = tags.length;
          } else {
            colorIndex = tags.length % KEYWORD_COLOR_LIST.length;
          }
          const color = KEYWORD_COLOR_LIST[colorIndex]?.hexcode;
          const fontColor = KEYWORD_COLOR_LIST[colorIndex]?.fontColor;
          const formatedData = {
            ...data,
            singleWordPhraseKeywords: [
              ...tags,
              {
                name: e.target.value.trim(),
                backgroundColor: color,
                fontColor: fontColor,
              },
            ],
            email: context?.user?.attributes?.email,
          };
          try {
            // send keyword has event
            if (!keywordEventSent && formatedData?.singleWordPhraseKeywords?.length === 1) {
              setKeywordEventSent(true)
              sendEvent({
                eventName: trackEvents.FEED_HAS_KEYWORD,
                payload : {
                  feed_name : socket
                }
              })
            }
            postSingleColumnKeyword(formatedData)
            setTag("");
            setColConfig(formatedData);
          } catch (err) {
            message.error("Something went wrong");
          }
        } else {
          message.info("You have reached the maximum limit of keywords.");
        }
      }
    }
  };

  const deleteKeyword = (name: any) => {
    const tags = data?.singleWordPhraseKeywords;
    const existingTags = tags
      .filter((item: any) => item.name !== name)
      ?.map((item: any, index: number) => {
        let colorIndex = 0;
        if (index < KEYWORD_COLOR_LIST.length) {
          colorIndex = index;
        } else {
          colorIndex = index % KEYWORD_COLOR_LIST.length;
        }
        const color = KEYWORD_COLOR_LIST[colorIndex]?.hexcode;
        const fontColor = KEYWORD_COLOR_LIST[colorIndex]?.fontColor;
        return { ...item, backgroundColor: color, fontColor: fontColor };
      });
    if (tags.length > 0) {
      const formatedData = {
        ...data,
        singleWordPhraseKeywords: existingTags,
        email: context?.user?.attributes?.email,
      };
      try {
        if (formatedData?.singleWordPhraseKeywords?.length === 0) {
          setKeywordEventSent(false)
        }
        postSingleColumnKeyword(formatedData)
        setTag("");
        setColConfig(formatedData);
      } catch (err) {
        message.error("Something went wrong");
      }
    }
  };

  // const addGroupedKeyword = (e: any) => {
  //   if (groupedTags.length < 5) {
  //     setGroupedTags([
  //       ...tags,
  //       {
  //         id: 1,
  //         name: e.target.value,
  //         color: "#ff644e",
  //       },
  //     ]);
  //   }
  // };

  // const deleteGroupedKeyword = (name: any) => {
  //   if (groupedTags.length > 0) {
  //     setGroupedTags(groupedTags.filter((item: any) => item.name !== name));
  //   }
  // };

  // useEffect(() => {
  //   console.log("tracker send keyword hightlight event")
  // }, [])


  return (
    <>
      <div
        className={"v2-edit-panel-keyword-div"}
        hidden={loading}
      >
        {contextHolder}

        <SingleKeywordHighlights
          props={props}
          tags={data?.singleWordPhraseKeywords}
          tag={tag}
          setTag={setTag}
          addKeyword={addKeyword}
          deleteKeyword={deleteKeyword}
        />



        {/* <div
        className={
          hasSubscriptionForAdsRemoval===true
            ? ""
            : "disabled"
        }
      >
        <GroupKeywordHighlights
          tags={groupedTags}
          addKeyword={addGroupedKeyword}
          deleteKeyword={deleteGroupedKeyword}
        />
      </div> */}

        <div className={isUserLoggedIn ? "" : "div-disabled"}>

          <Divider style={{ padding: "0", margin: "10px 0" }} />

          <div>Alert Settings</div>
          {/* <div className="v2-edit-panel-alert-settings-div">
            <div className="v2-edit-panel-alert-settings-item">
              <Switch
                size="small"
                checked={data.displayInAlertToster}
                onChange={(e) => {
                  const formatedData = {
                    ...data,
                    displayInAlertToster: e,
                    email: context?.user?.attributes?.email,
                  };
                  postSingleColumnKeyword(formatedData)
                    .then((res) => {
                      setData(formatedData);
                    })
                    .catch((err) => {
                      console.log(err);
                      alert("Something went wrong");
                    });
                }}
              />
              <div style={{ padding: "0 5px" }}>Display in alert toaster</div>
            </div>
            <Tooltip 
              title="This is message1"
              showArrow={false}
              placement='leftTop'
            >
              <InfoCircleOutlined className="v2-edit-panel-info-icon" />
            </Tooltip>
          </div> */}

          <div className="v2-edit-panel-alert-settings-div">
            <div className="v2-edit-panel-alert-settings-item">
              <Switch
                size="small"
                checked={data.highlightEntireCard}
                onChange={(e) => {
                  const formatedData = {
                    ...data,
                    highlightEntireCard: e,
                    email: context?.user?.attributes?.email,
                  };
                  try {
                    postSingleColumnKeyword(formatedData)
                    setTag("");
                    setColConfig(formatedData);
                  } catch (err) {
                    message.error("Something went wrong");
                  }
                }}
              />
              <div style={{ padding: "0 5px" }}>Highlight entire card</div>
            </div>
            <Tooltip
              title="As well as highlighting the your words or phrases, the border of the entire card in which they appear will also be highlighted."
              showArrow={false}
              placement='bottom'
            >
              <InfoCircleOutlined className="v2-edit-panel-info-icon" />
            </Tooltip>
          </div>

          <div className="v2-edit-panel-alert-settings-div">
            <div className="v2-edit-panel-alert-settings-item">
              <Switch
                size="small"
                checked={data.audioAlert}

                onChange={(e) => {
                  const formatedData = {
                    ...data,
                    audioAlert: e,
                    email: context?.user?.attributes?.email,
                  };
                  try {
                    postSingleColumnKeyword(formatedData)
                    setTag("");
                    setColConfig(formatedData);
                  } catch (err) {
                    message.error("Something went wrong");
                  }
                }}
              />
              <div style={{ padding: "0 5px" }}>Audio alert</div>
            </div>
            <Tooltip
              title="Receive an audio alert when your words or phrases appear in a post."
              showArrow={false}
              placement='bottom'
            >
              <InfoCircleOutlined className="v2-edit-panel-info-icon" />
            </Tooltip>
          </div>

          <Divider style={{ padding: "0", margin: "10px 0" }} />
        </div>
      </div>

      {loading && <Skeleton active loading={loading} />}
    </>
  );
};

export default KeywordHighlight;
