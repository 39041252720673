import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GlobalProps } from "src/interface/PiqState";
import { updateTitleBySlug } from "src/store/PiQColumns";
import { feedAxiosInstance } from "src/store/utility";

const CustomFeedNameEdit: React.FC<{
  socket: string;
  props: GlobalProps;
  handleChangeTitle: any
  title: any
  setTitle: any
}> = ({ socket, props, handleChangeTitle, title, setTitle }) => {
  const [showCount, setShowCount] = useState(false);

  const dispatch = useDispatch()
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));

  const customeFeeds = useSelector((state: any) => {
    return state?.piqcolumns?.customFeeds
  })
  useEffect(() => {
    const feedToEdit = customeFeeds.find((feed: any) => feed.slug === socket)
    if (feedToEdit) {
      setTitle(feedToEdit?.title)
    }
  }, [customeFeeds])


  return (
    <div className="v2-edit-panel-single-words-container">
      <Input
        placeholder="Name your feed + [enter]"
        onPressEnter={(e) => handleChangeTitle(e)}
        onChange={(e) => setTitle(e.target.value)}
        allowClear
        value={title}
        showCount={showCount}
        maxLength={25}
        autoComplete="off"
        onFocus={() => setShowCount(true)}
        onBlur={(e) => {
          setShowCount(false);
          handleChangeTitle(e)
        }}
      />
      {/* <input value={title} onChange={handleChangeTitle}></input> */}
    </div>
  );
};

export default CustomFeedNameEdit;
