import React from "react";
import OriginTitleComponent from "../Feeds/OriginTitle";
import TitleComponent from "../Feeds/Title";
import ContentComponent from "../Feeds/Content";
import { GlobalProps, SocketNames } from "src/interface/PiqState";
import ImageComponent from "../Feeds/Image";
import PublishedDateComponent from "../Feeds/PublisedDate";
import { SingleKeywordColConfig } from "src/interface/keyword";
import BreakingTag from "../Feeds/BrakingTag";
import MnemonicComponent from "../Feeds/Mnemonic";
import SectionTag from "../Feeds/SectionTag";
import { useSelector } from "react-redux";
import { customFeedsSockets } from "src/SuiteConfig/columnConfiguration";
import StockTag from "../Feeds/StockTag";
export const CustomFeedItem: React.FC<{
    item: any;
    socket: SocketNames;
    twitterAccount?: string;
    colConfig: SingleKeywordColConfig;
    props: GlobalProps;
    sourceName: string
}> = ({ item, socket, twitterAccount, colConfig, props, sourceName }) => {
    const [showShareIcon, setShowShareIcon] = React.useState(false);

    const { darkMode } = useSelector((state: any) => ({
        darkMode: state?.user?.darkMode,
    }));


    return (
        <div
            onMouseOver={() => setShowShareIcon(true)}
            className={`feed_item ${darkMode ? "feed_item_dark" : ""}`}
            onMouseLeave={() => setShowShareIcon(false)}
        >
            <PublishedDateComponent
                showShareIcon={showShareIcon}
                props={props}
                item={item}
                socket={socket}
                twitterAccount={twitterAccount ? twitterAccount : ""}
            />
            <TitleComponent
                item={item}
                socket={socket}
                colConfig={colConfig}
                props={props}
            />

            {item?.version === 1 && <BreakingTag />}
            {socket === "tickerApp" && (
                <MnemonicComponent item={item} socket={socket} props={props} />
            )}
            {socket !== "harkster" && socket !== "econostream" && (
                <ImageComponent item={item} />
            )}
            <ContentComponent item={item} socket={socket} props={props} />
            <OriginTitleComponent item={item} socket={socket} />

            {(socket === "newsquawk" || socket === "newsquawk-delay") && (
                <SectionTag item={item} />
            )}
            {(socket === "hammerstone") && (
                <StockTag item={item} />
            )}

            <div style={{ color: darkMode ? "#fff" : "black", marginTop: "10px" }}>Source: <span style={{ textTransform: "capitalize" }}>{sourceName}</span></div>
        </div>
    );
};
