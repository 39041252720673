import React from "react";
import { PartnerFeedComponent } from "src/development/Container/Web/Components/Feeds/PartnerFeeds";
import { TwitterListComponent } from "src/development/Container/Web/Components/Feeds/TwitterListColumn";
import { TradingViewComponent } from "src/development/Container/Web/Components/Feeds/TradingView/TradingView";
import { VideoPlayer } from "src/development/Container/Web/Components/Feeds/VideoPlayer";
import { GlobalProps, LayoutProps, SocketNames } from "src/interface/PiqState";
import { InvestingEconomicCalenderComponent } from "src/development/Container/Web/Components/Feeds/InvestingEconomicCalender";
import { CompleteIntelComponent } from "src/development/Container/Web/Components/Feeds/CompleteIntel";
import { TelegramComponent } from "src/development/Container/Web/Components/Feeds/Telegram";
import { IndividualFeedProvider } from "src/context/IndividualFeedProvider";
import Podcast from "src/development/Container/Web/Components/Feeds/Podcast";
import { CustomFeedComponent } from "src/development/Container/Web/Components/Feeds/CustomFeedComponent";
import { CmeGroupApp } from "src/development/Container/Web/Components/Feeds/CmeGroupApp/CmeGroupApp";
import MarketOverview from "src/development/Container/Web/Components/Feeds/MarketOverview/MarketOverview";
import StockScreener from "src/development/Container/Web/Components/Feeds/StockScreener/StockScreener";
import ForexCrossRates from "src/development/Container/Web/Components/Feeds/ForexCrossRates/ForexCrossRates";
import { customFeedsSockets } from "./columnConfiguration";
import PolymarketWidget from "src/development/Container/Web/Components/Feeds/PolymarketWidget";
import CryptoCompareCryptoNews from "src/development/Container/Web/Components/Feeds/CryptoCompareCryptoNews/CryptoCompareCryptoNews";
import CryptoCompareAdvancedChart from "src/development/Container/Web/Components/Feeds/CryptoCompareAdvancedChart/CryptoCompareAdvancedChart";
import CryptoComparePrices from "src/development/Container/Web/Components/Feeds/CryptoComparePrices/CryptoComparePrices";

const getChartComponet = (
  socket: SocketNames,
  props: GlobalProps,
  layoutProps: LayoutProps
) => {
  switch (socket) {
    case "tradingview":
      return (
        <TradingViewComponent
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );
    case "investing-economic-calender":
      return (
        <InvestingEconomicCalenderComponent
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );
    case "complete-intel":
      return (
        <CompleteIntelComponent
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );

    case "marketoverview":
      return (
        <MarketOverview
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );

    case "stockscreener":
      return (
        <StockScreener
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );
    case "cryptocomparecryptonews":
      return (
        <CryptoCompareCryptoNews
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );
    case "cryptocompareadvancedchart":
      return (
        <CryptoCompareAdvancedChart
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );
    case "cryptocompareprices":
      return (
        <CryptoComparePrices
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );

    case "forexcrossrates":
      return (
        <ForexCrossRates
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );

    case "electionwinnertrump":
    case "electionwinnerkamala":
    case "popularvotewinnertrump":
    case "popularvotewinnerkamala":
    case "willbidenfinishhisterm":
      return (
        <PolymarketWidget
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      )
  }
};

const getAppComponet = (
  socket: SocketNames,
  props: GlobalProps,
  layoutProps: LayoutProps
) => {
  switch (socket) {
    case "telegram":
      return (
        <TelegramComponent
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );

    case "cmegroup-app":
      return (
        <CmeGroupApp
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );
  }
};

export const getWebFeedComponent = (
  colString: SocketNames,
  props: GlobalProps,
  layoutProps: LayoutProps,
  c: any
) => {

  if (customFeedsSockets.includes(colString)) {
    return {
      socket: colString,
      component: (
        <IndividualFeedProvider feedId={colString} socket={colString}>
          <CustomFeedComponent
            socket={colString}
            props={props}
            layoutProps={layoutProps}
            feedData={c || { slug: colString }}
          />
        </IndividualFeedProvider>
      ),
    };
  }

  if (
    c?.componentType === "NEWS_FEED_COMPONENT" ||
    c?.componentType === "PREMIUM_FEED_COMPONENT"
  ) {
    return {
      socket: c.slug,
      component: (
        <IndividualFeedProvider feedId={c?._id} socket={c.slug}>
          <PartnerFeedComponent
            socket={c.slug}
            props={props}
            layoutProps={layoutProps}
          />
        </IndividualFeedProvider>
      ),
    };
  }

  if (c?.componentType === "TWITTER_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: (
        <IndividualFeedProvider feedId={c?._id} socket={c?.slug}>
          <TwitterListComponent
            socket={c.slug}
            props={props}
            layoutProps={layoutProps}
          />
        </IndividualFeedProvider>
      ),
    };
  }
  if (c?.componentType === "CUSTOM_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: (
        <IndividualFeedProvider feedId={c?._id} socket={c?.slug}>
          <CustomFeedComponent
            socket={c.slug}
            props={props}
            layoutProps={layoutProps}
            feedData={c}
          />
        </IndividualFeedProvider>
      ),
    };
  }

  if (c?.componentType === "SOCIAL_APP_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: getAppComponet(c.slug, props, layoutProps),
    };
  }

  if (c?.componentType === "CHART_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: getChartComponet(c.slug, props, layoutProps),
    };
  }
  if (c?.componentType === "APP_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: getAppComponet(c.slug, props, layoutProps),
    };
  }
  if (c?.componentType === "PIQ_PODCAST_COMPONENT") {
    return {
      socket: c.slug,
      component: (
        <Podcast socket={c.slug} props={props} layoutProps={layoutProps} />
      ),
    };
  }

  if (c?.componentType === "TUTORIAL_COMPONENT") {
    return {
      socket: c.slug,
      component: getAppComponet(c.slug, props, layoutProps),
    };
  }

  if (c?.componentType === "VIDEO_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: (
        <VideoPlayer socket={c.slug} props={props} layoutProps={layoutProps} />
      ),
    };
  } else {
    return {
      socket: colString,
      component: (
        <VideoPlayer
          socket={colString}
          props={props}
          layoutProps={layoutProps}
        />
      ),
    };
  }
};
