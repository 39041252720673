import newsquawk from "src/style/images/newsquawk.png";
import hammerstone from "src/style/images/Hammerstone-logo.png";

export const premiumDataDetails = [
  // {
  //   _id: "1",
  //   title: "Hammerstone",
  //   price: "100",
  //   termsRequired: true, // terms and condition required for checkout page or not
  //   checkoutTerms: true, //checkout user term checked or not
  //   piqTermsRequired: false, ///// terms and condition required for appselector page for piq to email or not
  //   checked: false, //appselector user checked or not
  //   img: hammerstone,
  //   paid: false,
  // },
  {
    _id: "2",
    title: "Newsquawk",
    price: "140",
    termsRequired: true, // terms and condition required for checkout page or not
    checkoutTerms: true, //checkout user term checked or not
    piqTermsRequired: false, ///// terms and condition required for appselector page for piq to email or not
    checked: false, //appselector user checked or not
    img: newsquawk,
    paid: false,
  }

];
