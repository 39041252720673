/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { executeScroll, handleColumnScroll } from "src/utils/common";
import { NewsFeedProps } from "src/interface/PartnerFeed";
import { socket as socketInstance } from "src/Socket";
import ScrollToTop from "../scrollToTop";
import { fetchCustomFeedData, onLoadSocketData } from "src/store/PIQ/action";
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import { useAuthContext } from "src/context/AuthProvider";
import useSound from "use-sound";
import dingSound from "src/sound/ding.mp3";
import { checkIfDivHasHighlight, filterColFeed } from "src/utils/utilFunction";
import useColConfig from "src/Hooks/useColConfig";
import useFeeds from "src/Hooks/useFeeds";
import { LoadMoreButton } from "src/development/Component/LoadMoreButton";
import useInFeedAds from "src/Hooks/useInFeedAds";
import { useSelector } from "react-redux";
import FeedSearch from "./FeedSearch";
import { IndividualFeedContext } from "src/context/IndividualFeedProvider";
import { message } from "antd";
import CustomColumnHeader from "src/development/Component/ColumnHeader/Custom-Column-Header";
import { feedAxiosInstance } from "src/store/utility";
import { updateSlugsBySlug, updateTitleBySlug } from "src/store/PiQColumns";
import CustomNewsFeedList from "src/development/Component/CustomFeed";
import useColumnHeader from "src/Hooks/useColumnHeader";
import { SocketNames } from "src/interface/PiqState";
import { useTrackerContext } from "src/context/TrackerProvider";
import { trackEvents } from "src/constants/tracker";
const PAGE_LIMIT = 50;
export const CustomFeedComponent: React.FC<NewsFeedProps> = ({
  socket,
  props,
  layoutProps,
  feedData
}) => {
  const dispatch = useDispatch();
  const dataColRef = useRef<HTMLDivElement>(null);
  const [displayScrollTop, setScrollTop] = useState<boolean>(false);
  const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const context: any = useAuthContext();
  const { colConfig } = useColConfig({ socket, showEditPanel });
  // const { colFilterConfig, openByDefault, setOpenByDefault } = useColFilterConfig({ feedId: feedId });
  const [data, setData] = useState<any[]>([]);
  const [keyToRefresh, setKeyToRefresh] = useState<any>(0);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [title, setTitle] = useState<string>("");
  const [isSocketLoading, setIsSocketLoading] = useState<boolean>(false);
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const { getColumnDetail, Columns } = useFeeds(props);
  const column = getColumnDetail(socket);
  const { pushAdsToColumn, getColumnAds } = useInFeedAds(props);
  const [adsCoordinates, setAdsCoordinates] = useState<any>([]);
  const [searchedText, setSearchedText] = useState<any>("");
  const column_ads = getColumnAds(socket);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [play, { stop }] = useSound(dingSound, { volume: 1 });

  const customeFeeds = useSelector((state: any) => {
    return state?.piqcolumns?.customFeeds
  })
  const { columns } = useSelector((state: any) => ({
    columns: state.piqcolumns.columns,
  }));
  const [isWidth2, setIsWidth2] = useState(false)
  useEffect(() => {
    const feedToEdit = customeFeeds.find((feed: any) => feed.slug === socket)
    if (feedToEdit) {
      setTitle(feedToEdit?.title)
    }
  }, [customeFeeds])

  const {
    loading,
    colFilterConfig,
    setColFilterConfig,
    openByDefault,
    setOpenByDefault,
    filterDisabled,
    setFilterDisabled,
  } = useContext(IndividualFeedContext);


  const { sendEvent } = useTrackerContext()

  const filteredLists = useMemo(
    () =>
      colFilterConfig?.is_active && !filterDisabled
        ? filterColFeed(data, colFilterConfig?.filters)
        : data,
    [data, colFilterConfig, filterDisabled]
  );

  useEffect(() => {
    (async () => {
      setIsSearchLoading(true);
      fetchCustomFeedData(
        setReload,
        PAGE_LIMIT,
        `customfeed/${feedData.slug}`,
        setData,
        setTotalPage,
        false,
        adsCoordinates,
        column_ads,
        searchedText
      );
      setIsSearchLoading(false);

      // audio.pause()
    })();
    return () => {
      setScrollTop(false);
      setShowEditPanel(false);
      setShowSearch(false);
      setIsFetching(false);
      setReload(false);
      setData([]);
      setPage(1);
      setTotalPage(1);
      stop();
    };
  }, [dispatch, socket]);



  useEffect(() => {
    const onData = async (socketName: string, newData: any) => {
      const title = columns.find((feed: any) => feed.slug === socketName)?.title || null;
      if (!newData?.data?.length || isSocketLoading) return;
      setIsSocketLoading(true);

      const addSourceName = newData.data.map((item: any) => ({
        ...item,
        sourceName: title,
      }));

      for (const item of addSourceName) {
        const tempData = { ...newData, data: [item] };
        const data_with_ads = pushAdsToColumn(
          tempData,
          socket,
          data,
          adsCoordinates,
          setAdsCoordinates
        );
        if (displayScrollTop) return
        await onLoadSocketData(
          data_with_ads,
          dataColRef,
          colConfig,
          checkIfDivHasHighlight,
          context,
          play,
          data,
          PAGE_LIMIT,
          socket,
          setData,
          false,
          setIsSocketLoading
        );
      }
    };

    const handleEvent = async (eventName: string, data: any) => {
      if (feedData?.slugs?.includes(eventName)) {
        await onData(eventName, data);
      }
    };

    socketInstance.onAny(handleEvent);

    return () => {
      socketInstance.offAny(handleEvent);
    };
  });

  //   const handleBuyNow = () => {
  //     if (!isUserLoggedIn) {
  //       props.setActiveDrawer("signup-drawer");
  //       return;
  //     }
  //     const updatedPremiumData: any = premiumDataDetails.map((data: any) =>
  //       data.title === "Newsquawk" ? { ...data, checked: true } : data
  //     );
  //     dispatch(updatePremiumAppData(updatedPremiumData));
  //     props.setActiveDrawer("upgrade-premium-app");
  //   };


  const handleSearch = (e: any) => {
    setFilterDisabled(true);
    const search_text = e.target.value;
    setSearchedText(search_text);
    setIsSearchLoading(true);
    fetchCustomFeedData(
      setReload,
      PAGE_LIMIT,
      `customfeed/${feedData.slug}`,
      setData,
      setTotalPage,
      false,
      adsCoordinates,
      column_ads,
      search_text
    );
    setIsSearchLoading(false);
  };


  const handleAddCustomFeed = async (socket: any, slugs: any) => {

    try {
      await feedAxiosInstance.put(`customfeed/${socket}`, { slugs });
      dispatch(updateSlugsBySlug({ slug: socket, slugs } as any))
      fetchCustomFeedData(
        setReload,
        PAGE_LIMIT,
        `customfeed/${socket}`,
        setData,
        setTotalPage,
        false,
        adsCoordinates,
        column_ads,
        searchedText
      );
      setIsSearchLoading(false);
      setShowEditPanel(true);
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (!isUserLoggedIn) {
      setShowSearch(false);
      setSearchedText("");
      fetchCustomFeedData(
        setReload,
        PAGE_LIMIT,
        `customfeed/${feedData.slug}`,
        setData,
        setTotalPage,
        false,
        adsCoordinates,
        column_ads,
        ""
      );
    }
  }, [isUserLoggedIn]);
  const handleChangeTitle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    try {
      await feedAxiosInstance.put(`customfeed/${socket}`, { title: newTitle });
      dispatch(updateTitleBySlug({ slug: socket, title: newTitle } as any))
    } catch (error) {
      console.error('Error updating custom feed title:', error);
      // Optionally, you can add error handling here, such as showing a notification to the user
    }
  }


  useEffect(() => {
    if (feedData?.slugs?.length === 0) {

      setShowEditPanel(true);
      setOpenByDefault([1, 2]);
    }
  }, [])

  useEffect(() => {
    setScrollTop(false);
  }, [filteredLists, filterDisabled]);

  useEffect(() => {
    if (displayScrollTop) {
      sendEvent({
        eventName: trackEvents.FEED_SCROLL,
        payload: {
          feed_name: socket
        }
      })
    }
  }, [displayScrollTop])


  useEffect(() => {

    if (dataColRef.current) {
      const { scrollTop } = dataColRef.current;
      if (!(dataColRef.current && scrollTop >= 90)) {
        fetchCustomFeedData(
          setReload,
          PAGE_LIMIT,
          `customfeed/${feedData.slug}`,
          setData,
          setTotalPage,
          true,
          adsCoordinates,
          column_ads,
          searchedText
        );
      }
    }

  }, [displayScrollTop])
  useEffect(() => {
    const isWidth2 = layoutProps?.piqSuiteLayout.some((item: any) => item.i === socket && item.w === 2);
    setIsWidth2(isWidth2)
  }, [layoutProps])

  return (
    <>
      <ColumnHeader
        navImg={column?.icon}
        title={title}
        isFetching={reload}
        fetchFunction={() => {
          setIsSearchLoading(true);

          fetchCustomFeedData(
            setReload,
            PAGE_LIMIT,
            `customfeed/${feedData.slug}`,
            setData,
            setTotalPage,
            false,
            adsCoordinates,
            column_ads,
            searchedText
          );
          setIsSearchLoading(false);


          // stop();
        }}
        socket={socket}
        handleEditPanel={() => {
          setOpenByDefault("");

          setShowEditPanel(true);

          setShowSearch(false);
        }}
        handleSearch={() => {
          if (!isUserLoggedIn) {
            props.setActiveDrawer("signup-drawer");
            message.warning("You need to login to access search");
          } else {
            // showEditPanel ? setShowSearch(false) : setShowSearch(!showSearch);
            if (showEditPanel) {
              setShowSearch(false);
            } else {
              setShowSearch(!showSearch);
              setFilterDisabled((prev) => !prev);
            }
          }
        }}
        toggleFilter={() => {
          if (!showEditPanel) {
            setShowEditPanel(true);
            setOpenByDefault("5");
            setShowSearch(false);
          } else {
            setShowEditPanel(false);
            setOpenByDefault("");
          }
        }}
        searchedText={searchedText}
      />
      {showSearch && (
        <FeedSearch handleSearch={handleSearch} searchedText={searchedText} />
      )}
      {showEditPanel && socket ? (
        <CustomColumnHeader
          props={props as any}
          setShowEditPanel={setShowEditPanel}
          socket={socket}
          layoutProps={layoutProps as any}
          defaultActivePanel={openByDefault}
          handleAddCustomFeed={handleAddCustomFeed}
          handleChangeTitle={handleChangeTitle}
          title={title}
          setTitle={setTitle}
          keyToRefresh={keyToRefresh}
          setKeyToRefresh={setKeyToRefresh}
          setOpenByDefault={setOpenByDefault}
        />
      ) : (
        <div
          ref={dataColRef}
          onScroll={() => {
            if (dataColRef.current) {
              const { scrollTop } = dataColRef.current;
              if (dataColRef.current && scrollTop >= 90) {
                setScrollTop(true);
              } else {
                setScrollTop(false);
              }
            }
          }}
          className="v2-suite-column"
        >
          {!reload && (
            <div className="list-container-article-viewer">
              <CustomNewsFeedList
                list={filteredLists}
                socket={socket}
                colConfig={colConfig}
                twitterAccount={column?.twitterAccount}
                props={props as any}
                isSearchLoading={isSearchLoading}
                searchedText={searchedText}
                filterActive={colFilterConfig?.is_active}
                isWidth2={isWidth2}
              />
            </div>
          )}

          <LoadMoreButton
            totalPage={totalPage}
            setIsSocketLoading={setIsSocketLoading}
            page={page}
            data={filteredLists}
            handleColumnScroll={handleColumnScroll}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            socket={socket}
            dataColRef={dataColRef}
            setScrollTop={setScrollTop}
            setPage={setPage}
            pageLimit={PAGE_LIMIT}
            setData={setData}
            api={`customfeed/${feedData.slug}?search=${searchedText}`}
            isFilterOn={colFilterConfig?.is_active && colFilterConfig?.filters?.length !== 0}
          />

          <ScrollToTop
            displayScrollTop={displayScrollTop}
            executeScrollToTop={() => {
              executeScroll(dataColRef);
              setIsSearchLoading(true);
              fetchCustomFeedData(
                setReload,
                PAGE_LIMIT,
                `customfeed/${feedData.slug}`,
                setData,
                setTotalPage,
                true,
                adsCoordinates,
                column_ads,
                searchedText
              );
              setIsSearchLoading(false);
              setScrollTop(false);
            }}
          />
        </div>
      )}
    </>
  );
};
