import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { GlobalProps, LayoutProps } from 'src/interface/PiqState';
import PodcastHeader from '../../../../Component/ColumnHeader/PodcastHeader';
import { Button, Skeleton } from 'antd';
import closeIcon from "src/style/images/close_icon.svg";
import ColumnHeaderOption from 'src/development/Component/ColumnHeader/Column-Header-Option';
import PodcastHeaderOption from 'src/development/Component/ColumnHeader/Podcast-Header-Option';

interface PodcastProps {
    socket: string;
    props: GlobalProps;
    layoutProps: LayoutProps;
}

const Podcast = ({
    socket,
    props,
    layoutProps
}: PodcastProps) => {

    const darkMode = useSelector((state: any) => state?.user?.darkMode);
    const { podcasts } = useSelector((state: any) => state?.podcasts);
    const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
    const [reloading, setReloading] = useState<boolean>(true);
    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const detail = useMemo(() => {
        return podcasts?.find((item: any) => item?.slug === socket);
    }, [podcasts])

    const reloadHandler = () => {
        setReloading(true);
        if (iframeRef && iframeRef?.current) {
            iframeRef.current.src += ""
            iframeRef.current.onload = () => setReloading(false);
        }
    }

    useEffect(() => {
        reloadHandler()
    }, [])

    return (
        <div className="podcast-player"
            style={{
                display: "flex",
                flexDirection: "column",
                height: "90%"
            }}
        >
            {/* header */}
            <PodcastHeader
                title={detail?.title}
                handleEditPanel={() => {
                    setShowEditPanel(true);
                }}
                reloadIframe={reloadHandler}
                reloading={reloading}
            />
            <div style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                height: "100%",
                flex: "1"
            }}>
                {
                    showEditPanel &&
                    <div style={{
                        position: "absolute",
                        left: "0",
                        top: "0",
                        height: "96.5%",
                        width: "100%"
                    }}>
                        <PodcastHeaderOption
                            props={props as any}
                            setShowEditPanel={setShowEditPanel}
                            layoutProps={layoutProps as any}
                            slug={detail?.slug}
                        />
                    </div>
                }{
                    detail && <div style={{
                        // padding: "2px 0px 2px 5px",
                        marginInline: "4px",
                        marginTop: "2px",
                        height: "calc(100% - 4px)",
                        width: "calc(100% - 4px)",
                    }}
                    >
                        {
                            reloading && <div style={{
                                position: "absolute",
                                left: "4px",
                                top: "2px",
                                height: "100%",
                                width: "100%",
                                background: darkMode ?
                                    "#515A67"
                                    : "#fff",
                                padding: "10px",
                            }}
                                className="v2-suite-column-skeleton"

                            >
                                <Skeleton
                                    active
                                    loading
                                />
                            </div>
                        }
                        {
                            <iframe
                                ref={iframeRef}
                                className="hytPlayerWrap"
                                src={detail?.url}
                                title={detail?.title}
                                // frameborder={"0"}
                                allowFullScreen
                                height={"100%"}
                                width={"100%"}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            ></iframe>
                        }
                    </div>
                }
            </div>

        </div >
    )
}

export default Podcast