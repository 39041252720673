import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { forexCrossRatesWidgetConfig } from 'src/constants/charts';

const ForexCrossRatesWidget: React.FC<{
    reload: boolean;
    setFetching: (fetching: boolean) => void;
    setReload: Dispatch<SetStateAction<boolean>>
}> = ({ reload, setFetching, setReload }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const darkMode = useSelector((state: any) => state?.user?.darkMode);

    useEffect(() => {
        const loadScript = () => {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js';
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = forexCrossRatesWidgetConfig(darkMode);

            if (containerRef.current) {
                containerRef.current.appendChild(script);
            }

            script.onload = () => {
                setFetching(false);
                setReload(false);
            };
        };

        // Remove existing script if any
        if (containerRef.current) {
            const existingScript = containerRef.current.querySelector('iframe');
            if (existingScript) {
                containerRef.current.removeChild(existingScript);
            }
        }

        // Load new script
        loadScript();

        return () => {
            if (containerRef.current) {
                const scriptElement = containerRef.current.querySelector('script');
                if (scriptElement) {
                    containerRef.current.removeChild(scriptElement);
                }
            }
        };
    }, [darkMode, reload]);

    return (
        <div className={`tradingview-widget-container forexcrossrates-widget-container ${darkMode ? "dark" : ""}`} ref={containerRef} style={{ height: '100%' }}>
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
};

export default ForexCrossRatesWidget;
