import React, { useEffect } from "react";
import useInFeedAds from "src/Hooks/useInFeedAds";
import config from "src/config";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import { GlobalProps } from "src/interface/PiqState";
import PepperstoneAd from "../PepperstoneAd";

export const InFeedAds: React.FC<{ item: any, props: GlobalProps, isWidth2: boolean }> = ({ item, props, isWidth2 }) => {
    const { handleAdsClick } = useInFeedAds(props)
    const { sendEvent } = useTrackerContext()
    return (
        <>
            {
                item?.list_item_type === 'IN_FEED_ADS' && <div
                    className={`v2-suite-column-card-item`}
                    style={{ cursor: 'pointer', padding: "0px !important" }} onClick={() => {
                        handleAdsClick(item);
                        sendEvent({
                            eventName: trackEvents.ADD_CLICK
                        })
                    }}>

                    {item?.type === "PEPPERSTONE" ?
                        isWidth2 ?
                            <img src={config?.FEED_URL + item?.image_url}
                                alt="https://piqsuite.com"
                                style={{ width: "-webkit-fill-available" }}
                            /> : <></>

                        : <img src={config?.FEED_URL + item?.image_url}
                            alt="https://piqsuite.com"
                            style={{ width: "-webkit-fill-available" }}
                        />}
                </div>
            }
        </>
    )
}