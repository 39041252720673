"use client";

import React, { useEffect } from "react";

export const LocationTrackerContext = React.createContext({
    isUS: true,
});

export const useLocationContext = () =>
    React.useContext(LocationTrackerContext);

export const LocationProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [isUS, setUS] = React.useState(true);
    useEffect(() => {
        const fetchCountryName = async () => {
            try {
                const response = await fetch(
                    "https://api.bigdatacloud.net/data/reverse-geocode-client"
                );
                if (response.ok) {
                    const data = await response.json();

                    if (
                        data?.countryName !== "United States of America" &&
                        data?.countryCode !== "US"
                    ) {
                        setUS(false);
                    }
                } else {
                    console.error("Failed to geo data");
                }
            } catch (error) {
                console.error("Error fetching country name:", error);
            }
        };

        fetchCountryName();
    }, []);

    return (
        <LocationTrackerContext.Provider
            value={{
                isUS,
            }}
        >
            {children}
        </LocationTrackerContext.Provider>
    );
};
