import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuthContext } from "./AuthProvider";
import { getFeedFilter, postFeedFilter } from "src/store/PIQ/action";
import { filter } from "src/interface/filter";
import { useTrackerContext } from "./TrackerProvider";
import useColConfig from "src/Hooks/useColConfig";

interface IndividualFeedContext {
  loading: boolean;
  colFilterConfig: ColFilterConfig;
  sendFilterConfig: (data: ColFilterConfig, sendPost?: boolean) => void;
  openByDefault: any;
  setOpenByDefault: Dispatch<SetStateAction<any>>;
  setColFilterConfig: Dispatch<SetStateAction<ColFilterConfig>>;
  filterDisabled: boolean;
  setFilterDisabled: Dispatch<SetStateAction<boolean>>;
  emptyFilter: boolean;
  sendFeedSesionDuration: (socket: string) => void;
  keywordEventSent: boolean;
  setKeywordEventSent: Dispatch<SetStateAction<boolean>>
}

export const IndividualFeedContext = createContext<IndividualFeedContext>({
  loading: false,
  colFilterConfig: {
    feed_id: "",
    filters: [],
    is_active: false,
  },
  sendFilterConfig: () => { },
  openByDefault: "",
  setOpenByDefault: () => { },
  setColFilterConfig: () => { },
  filterDisabled: false,
  setFilterDisabled: () => { },
  emptyFilter: false,
  sendFeedSesionDuration: () => { },
  keywordEventSent: false,
  setKeywordEventSent: () => { }
});

export const useIndividualFeedContext = () =>
  React.useContext(IndividualFeedContext);


export interface ColFilterConfig {
  feed_id: string;
  filters: filter[];
  is_active: boolean;
}

export const IndividualFeedProvider: React.FC<{
  children: React.ReactNode;
  feedId: string;
  socket: string;
}> = ({ children, feedId, socket }) => {
  const { isSignedIn } = useAuthContext();

  const { sendActiveFeedDuration } = useTrackerContext();

  const [openByDefault, setOpenByDefault] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [filterDisabled, setFilterDisabled] = useState<boolean>(false);

  const [keywordEventSent, setKeywordEventSent] = useState<boolean>(false);

  const [colFilterConfig, setColFilterConfig] = useState<ColFilterConfig>({
    feed_id: feedId,
    filters: [],
    is_active: false,
  });

  const emptyFilter = useMemo(() => colFilterConfig?.filters?.length === 0 ? true : (colFilterConfig?.filters?.[0]?.keyword === "" ? true : false), [colFilterConfig?.filters])

  const feedInitTime = useMemo(() => new Date().getTime(), [feedId])

  const sendFilterConfig = async (
    data: ColFilterConfig,
    sendPost: boolean = true
  ) => {
    setColFilterConfig(data);
    if (!isSignedIn || !sendPost) return;
    if (
      data?.filters?.length === 1 &&
      [...data?.filters]?.pop()?.keyword === ""
    )
      return;
    try {
      const res = await postFeedFilter(data);
    } catch (err) {
      console.log("err = ", err);
    }
  };

  const sendFeedSesionDuration = (
    socket: string) => {
    sendActiveFeedDuration({
      feedInitTime,
      payload: {
        feed_name: socket
      }
    })
  }

  useEffect(() => {
    if (feedId && isSignedIn) {
      (async () => {
        try {
          const res = await getFeedFilter(feedId);
          if (res?.data?.data !== null) {
            setColFilterConfig({
              feed_id: res?.data?.feed_id,
              filters: res?.data?.filters,
              is_active: res?.data?.is_active,
            });
          }
        } catch (err) { }
      })();
    } else {
      setColFilterConfig({
        feed_id: feedId,
        filters: [],
        is_active: false,
      })
    }
  }, [feedId, isSignedIn]);

  return (
    <IndividualFeedContext.Provider
      value={{
        loading,
        colFilterConfig,
        openByDefault,
        setOpenByDefault,
        sendFilterConfig,
        setColFilterConfig,
        filterDisabled,
        setFilterDisabled,
        emptyFilter,
        sendFeedSesionDuration,
        keywordEventSent,
        setKeywordEventSent
      }}
    >
      {children}
    </IndividualFeedContext.Provider>
  );
};
